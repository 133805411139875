import  { useCallback, useEffect, useMemo ,useState} from 'react';
import {useSelector,useDispatch } from 'react-redux';
import { get, keys } from 'lodash';
import { TActionTypeEnum } from '@/types/global';
import { apiSession,apiOauth } from '@/api/session';
import { TCurrentUser } from '@/types/user';
import {getInitUser} from '@/store/module/global';
import {IStoreState} from '@/store/types';
import { getEnumValues } from '@/utils/enum';
import { TNetworkRoleSwitchs, TNetworkUser } from '@/types/network';
import {store} from '@/utils/utils'
import {
    TNetworkRoleSwitchLevelEnum,
    TNetworkRoleSwitchNameEnum,
    TUserRoleEnum,
} from '@/types/enum';

const useGlobalState = () => {
    const state = useSelector((state:IStoreState) =>{
        return state.global;
      });
    const dispatch = useDispatch();
    // 是否登录了
    const isLogin = !!state.currentUser.id;
    // 存储之前的networkId,用于比较
    // const networkIdRef = useRef<number>(0);
    const [saveNetworkId,setSaveNetworkId] = useState<number>(0);

    //从路由中获取网络ID,用于登陆后的初始化
    const networkReg = /\/network\/(\d+)\/.*/;
    const getRouteNetworkId = () => {
        const networkId = window.location.hash.match(networkReg)?.[1] || 0;
        return Number(networkId);
    };
    //获取当前登陆用户信息
    const getSession = useCallback(async () => {
        await apiSession().then(res => {
            const data = res.data as TCurrentUser;
            store.write('t_uid', data.id);
            dispatch({
                type: TActionTypeEnum.SET_USER,
                payload: data,
            });
        })
        await apiOauth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

   //退出登陆
    const logout = useCallback(() => {
          localStorage.clear()
          store.remove('t_uid');
          dispatch({
            type: TActionTypeEnum.SET_USER,
            payload: getInitUser(),
          });
          window.history.replaceState(null,'','/login')
        // 因为退出的时候清不掉上一次记录的currentNetworkId所以需要刷新一下，不然进去子应用会请求上一次的列表数据
        //   window.location.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //设置网络id
    const setCurrentNetworkId = useCallback((networkId?: number | string) => {
        if (Number(networkId) !== Number(saveNetworkId)) {
            dispatch({
                type: TActionTypeEnum.SET_NETWORK_ID,
                payload: Number(networkId),
            });
        }
        setSaveNetworkId(Number(networkId)) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //设置当前项目ID
    const setCurrentCampaignId = useCallback((campaignId?: number | string) => {
        dispatch({
                type: TActionTypeEnum.SET_CAMPAIGN_ID,
                payload: Number(campaignId),
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // 当前用户在当前网络的信息
    const networkUser: TNetworkUser = useMemo(
        () => get(state, `currentUser.networks[${state.currentNetworkId}]`, {}),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state.currentUser, state.currentNetworkId],
    );

    // 当前网络拥有的模块权限
    const networkSwitchs = useMemo(() => get(networkUser, 'network.switchs', {}), [networkUser]);

    // 当前用户在当前网络下拥有的模块权限
    const networkRoleSwitchs: TNetworkRoleSwitchs = useMemo(() => {
        // 如果是网络创建者,不会返回 networkRole 字段
        if (!networkUser.networkRole) {
            // 网络创建者拥有此网络的所有权限
            return getEnumValues(TNetworkRoleSwitchNameEnum).reduce(
                (memo, switchName) => ({
                    ...memo,
                    [switchName]: TNetworkRoleSwitchLevelEnum.ALL,
                }),
                {},
            );
        }
        return get(networkUser, 'networkRole.switchs', {});
    }, [networkUser]);

    //登陆完之后设置当前用户的网络
    useEffect(() => {
        if (!isLogin) return;
        const networkId = Number(getRouteNetworkId()) || Number(localStorage.getItem("networkId")) ||
        Number(keys(state.currentUser.networks)[0])
        setCurrentNetworkId(
            networkId
        );
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isLogin, state.currentUser.networks]);
    return {
        ...state,
        getSession,
        logout,
        setCurrentNetworkId,
        setCurrentCampaignId,
        isLogin,
        network: networkUser.network || {}, // 当前网络信息
        networkUser,
        networkSwitchs, // 当前网络拥有的模块权限
        networkRoleSwitchs, // 当前用户在当前网络下拥有的模块权限
        adminSwitchs: state.currentUser.switchs || {}, // 当前用户如果是 admin,则会有对应的后台相关的模块权限
        isAdmin: state.currentUser.role === TUserRoleEnum.ADMIN, // 是否是管理员
        // 当前网络是否可用(有效)
        isNetworkAvailable: !!state.currentUser.networks[state.currentNetworkId],
    };
};

export default useGlobalState;
