/*
 * @Description: 媒体初始化 Hook，用于处理媒体列表、广告类型和监测代码配置的初始化
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2023-11-03 16:35:09
 */
import _ from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { apiMedias, apiMediaAdTypes, apiMediaCodeConfigs } from '@/api/medias';
import { TMediaModel, TMediaAdTypeModel, TMediaCodeConfigModel } from '@/types/media';
import { ALL_MEDIA_IDS } from '@/data/media-id';
import { TActionTypeEnum } from '@/types/global';

/**
 * Hook 的输入参数接口
 */
interface TProps {
  isLogin?: boolean; // 是否已登录
}

/**
 * 媒体模型数组接口
 */
interface MediaModelArray {
  [index: number]: TMediaModel
}

/**
 * 媒体广告类型模型数组接口
 */
interface MediaAdTypeModelArray {
  [index: number]: TMediaAdTypeModel
}

/**
 * 媒体代码配置模型数组接口
 */
interface MediaCodeConfigModelArray {
  [index: number]: TMediaCodeConfigModel
}

/**
 * 媒体初始化 Hook
 * @param {TProps} props - Hook 的输入参数
 * @returns {void}
 */
const useInitMedia = ({ isLogin }: TProps) => {
  // 状态定义
  const [mediaList, setMediaList] = useState<MediaModelArray>([]); // 媒体列表
  const [mediaAdTypes, setMediaAdTypes] = useState<MediaAdTypeModelArray>([]); // 媒体广告类型
  const [mediaCodeConfigs, setMediaCodeConfigs] = useState<MediaCodeConfigModelArray>([]); // 媒体代码配置
  const dispatch = useDispatch();

  /**
   * 重新排序列表，确保原生唤醒和小程序相关字段在第一位
   * @param {any[]} list - 需要排序的列表
   * @returns {any[]} 排序后的列表
   */
  const reSortList = (list: any[]) => {
    const filterList = _.filter(list, (item) => _.includes(['ad_type', 'is_native_wake', 'is_mini_app', 'miniapp_id', 'miniapp_path'], item.name))
    const elseList = _.filter(list, (item) => !_.includes(['ad_type', 'is_native_wake', 'is_mini_app', 'miniapp_id', 'miniapp_path'], item.name))
    return filterList.concat(elseList)
  };

  /**
   * 处理媒体数据，包括媒体列表、广告类型和监测代码配置
   * @param {Object} params - 处理参数
   * @param {MediaModelArray} params.mediaList - 媒体列表
   * @param {MediaAdTypeModelArray} params.mediaAdTypes - 媒体广告类型
   * @param {MediaCodeConfigModelArray} params.mediaCodeConfigs - 媒体代码配置
   * @param {boolean} params.isMounted - 组件是否已挂载
   * @returns {Object|null} 处理后的媒体数据
   */
  const processMediaData = useCallback(({
    mediaList,
    mediaAdTypes,
    mediaCodeConfigs,
    isMounted
  }: {
    mediaList: MediaModelArray;
    mediaAdTypes: MediaAdTypeModelArray;
    mediaCodeConfigs: MediaCodeConfigModelArray;
    isMounted: boolean;
  }) => {
    try {
      // 过滤并处理媒体列表
      const usingMedias = _.filter(mediaList, (media) => ALL_MEDIA_IDS.includes(media.id));
      const configMedias = _.filter(mediaList, (media) => media.type === '1');
      const fUsingMedias = _.map(usingMedias, (media) => _.pick(media, ['id', 'name_cn']));
      let fConfigMedias = _.map(configMedias, (media) => _.pick(media, ['id', 'name_cn']));
      if (!isMounted) return null;

      // 处理媒体广告类型分组
      let fConfigMediasIds = _.map(fConfigMedias, (item) => item.id)
      let enableMeidaAdTypes = _.filter(mediaAdTypes, (item) => _.includes(fConfigMediasIds, item.mediaId))
      let mediaAdTypesGroup = _.groupBy(enableMeidaAdTypes, (item) => +item.mediaId);
      let mediaAdTypesGroup2 = [];
      mediaAdTypesGroup2 = _.map(mediaAdTypesGroup, (item, index) => {
        // 处理广告类型
        const adTypes = _.map(item, (cItem, cIndex) => {
          return {
            'id': cItem.adTypeValue,
            'text': cItem.adTypeName,
            'disabled': cItem.status === 'enabled' ? false : true
          }
        })
        // 处理组件属性
        let itemAttrs = _.map(item, (cItem, cIndex) => cItem.transComponents)
        // 拓展组件属性
        _.forEach(itemAttrs, (item) => {
          _.forEach(item, (cItem, cIndex) => {
            // 处理 select 类型组件
            if (cItem.type === 'select') {
              if (cItem.name === 'position_select') {
                cItem.getData = () => Promise.resolve([])
              } else {
                const dataList = _.map(cItem.data, (itemData) => {
                  return {
                    id: itemData.value,
                    text: itemData.label
                  }
                })
                cItem.getData = () => Promise.resolve(dataList)
              }
            }
            // 处理原生唤醒组件
            if (cItem.name === 'is_native_wake') {
              cItem.changeHandle = (context: any) => {
                return (e: any) => {
                  if (e.target.checked) {
                    context.$(".deeplink").removeClass('hidden');
                    if (context.$('input[name=is_mini_app]')) {
                      context.$('input[name=is_mini_app]').prop('checked', false).trigger('change')
                    }
                  } else {
                    context.$(".deeplink").addClass('hidden')
                  }
                }
              }
            }

            // 处理小程序组件
            if (cItem.name === 'is_mini_app') {
              cItem.changeHandle = (context: any) => {
                let index = 0
                return (e: any) => {
                  index++
                  const $miniAppEle = context.$('.mini_app_hook')
                  if (e.target.checked) {
                    $miniAppEle.removeClass('hidden')
                    context.$('input[name=is_native_wake]').prop('checked', false).trigger('change')
                  } else {
                    $miniAppEle.addClass('hidden')
                    if (index > 1) {
                      context.$('.mini_app_hook').find('input').val('')
                      context.model.set('mini_program_id', '')
                      context.model.set('mini_program_path', '')
                    }
                  }
                }
              }
            }
          })
        })

        // 处理广告位选择
        const firstItem = {
          name: 'ad_type',
          text: '配置类型',
          type: 'select',
          required: true,
          data: adTypes,
          defaultValue: adTypes[0]['id'],
          getData: () => Promise.resolve(adTypes),
          changeHandle: (context: any) => {
            let index = 0
            return (e: any) => {
              _.forEach(adTypes, (item) => {
                context.$(`.${item.id}`).addClass('hidden')
              })
              let typeId = e.target.value
              context.model.set('mediaGroupType', typeId)
              context.$(`.${typeId}`).removeClass('hidden')
              if (index > 0) {
                // 切换广告形式默认关闭原生唤醒和小程序
                context.$('input[name=is_mini_app]').prop('checked', false).trigger('change')
                context.$('input[name=is_native_wake]').prop('checked', false).trigger('change')
              } else {
                // 广告形式不在配置中默认关闭原生唤醒和小程序
                const adTypeIds = _.map(adTypes, (item) => item.id)
                if (!_.includes(adTypeIds, typeId)) {
                  context.$('input[name=is_mini_app]').prop('checked', false).trigger('change')
                  context.$('input[name=is_native_wake]').prop('checked', false).trigger('change')
                }
              }
              index++
            }
          }
        }

        // 处理属性列表
        itemAttrs = _.flatten(itemAttrs)
        itemAttrs.unshift(firstItem)
        // 筛选并处理唯一属性
        let onlyUnionAttrs = _.filter(itemAttrs, (item) => _.includes(['deeplink', 'miniapp_id', 'miniapp_path'], item.name))
        const elseUnionAttrs = _.filter(itemAttrs, (item) => !_.includes(['deeplink', 'miniapp_id', 'miniapp_path'], item.name))
        onlyUnionAttrs = _.unionBy(onlyUnionAttrs, 'name')
        let resList = elseUnionAttrs.concat(onlyUnionAttrs)
        let sortList = reSortList(resList)
        return {
          id: index,
          attrs: sortList
        }
      })

      // 处理媒体广告类型结果
      let resultConfigMediaAdTypes = {} as TMediaAdTypeModel
      _.forEach(mediaAdTypesGroup2, (item) => {
        resultConfigMediaAdTypes[`${item.id}`] = item.attrs
      })

      // 处理配置媒体 ID
      let fConfigMediaIds = _.map(mediaAdTypes, (item) => +item.mediaId)
      fConfigMediaIds = _.uniq(_.compact(fConfigMediaIds))
      fConfigMedias = _.filter(fConfigMedias, (item) => _.includes(fConfigMediaIds, +item.id))

      if (!isMounted) return null;

      // 存储媒体数据到 localStorage
      try {
        localStorage['medias'] = JSON.stringify(fUsingMedias);
        localStorage['configMedias'] = JSON.stringify(fConfigMedias);
      } catch (storageError) {
        console.error('存储媒体数据失败:', storageError);
      }

      if (!isMounted) return null;

      // 处理媒体监测代码配置
      let resMediaCodeConfigs = [] as any[]
      resMediaCodeConfigs = _.map(mediaCodeConfigs, (item) => {
        let impArr = _.filter(item.configs, (cItem) => cItem.type === 'imp');
        let clkArr = _.filter(item.configs, (cItem) => cItem.type === 'clk');
        let progressArr = _.filter(item.configs, (cItem) => !_.includes(['imp', 'clk'], cItem.type));
        let impObj = impArr?.length > 0 ? impArr[0] : {};
        let clkObj = clkArr?.length > 0 ? clkArr[0] : {};
        let progressObj = progressArr?.length > 0 ? _.map(progressArr, (item) => item.type) : [];
        return {
          mediaId: item.mediaId,
          impClkObj: {
            imp: impObj?.len ?? 0,
            clk: clkObj?.len ?? 0,
          },
          progressObj: progressObj,
          codeLimit: item.codeLimit,
          dpLimit: item.dpLimit,
          targetLimit: item.targetLimit
        }
      })

      // 处理媒体代码配置结果
      let groupedMediaCodeConfigs = _.groupBy(resMediaCodeConfigs, 'mediaId')
      let resultMediaCodeConfigs = {} as TMediaCodeConfigModel
      _.forEach(groupedMediaCodeConfigs, (item, key) => {
        if (item && item.length > 0) {
          resultMediaCodeConfigs[`${key}`] = {
            imgClkObj: item[0].impClkObj ?? {},
            progressObj: item[0].progressObj ?? [],
            codeLimit: item[0].codeLimit,
            dpLimit: item[0].dpLimit,
            targetLimit: item[0].targetLimit
          }
        }
      })
      // 返回处理后的媒体数据
      return {
        medias: fUsingMedias,
        configMedias: fConfigMedias,
        configMediaAdTypes: resultConfigMediaAdTypes,
        configMediaCodes: resultMediaCodeConfigs
      };
    } catch (error) {
      console.error('处理媒体数据失败:', error);
      return null;
    }
  }, []);

  /**
   * 初始化媒体数据
   * 当登录状态改变时触发
   */
  useEffect(() => {
    if (!isLogin) return;
    let isMounted = true;

    const initMediaData = async () => {
      try {
        const [res, configRes, codeConfigRes] = await Promise.all([
          apiMedias(),
          apiMediaAdTypes(),
          apiMediaCodeConfigs()
        ]);

        if (!isMounted) return;

        setMediaList(res.data as MediaModelArray);
        setMediaAdTypes(configRes.data as MediaAdTypeModelArray);
        setMediaCodeConfigs(codeConfigRes.data as MediaCodeConfigModelArray);
      } catch (error) {
        console.error('初始化媒体数据失败:', error);
      }
    };

    initMediaData();

    return () => {
      isMounted = false;
    };
  }, [isLogin]);

  /**
   * 处理媒体数据并更新 Redux store
   * 当媒体列表、广告类型或代码配置改变时触发
   */
  useEffect(() => {
    if (!mediaList || !mediaList[0]) return;

    let isMounted = true;

    const handleMediaData = async () => {
      const result = processMediaData({
        mediaList,
        mediaAdTypes,
        mediaCodeConfigs,
        isMounted
      });
      if (!isMounted || !result) return;

      dispatch({
        type: TActionTypeEnum.SET_MEDIAS,
        payload: result
      });
    };

    handleMediaData();

    return () => {
      isMounted = false;
    };
  }, [dispatch, mediaList, mediaAdTypes, mediaCodeConfigs, processMediaData]);

};

export default useInitMedia;
