/*
 * @Description: 
 * @Author: jiangdongdong@mininglamp.com
 * @Date: 2023-11-03 16:35:09
 */
import { request } from './request';
export function apiMedias() {
    return request({
      method: 'GET',
      url: `/api_v2/medias`,
      params: {
        startIndex: 0,
        maxResults: 5000
      }
    });
  }

export function apiMediaAdTypes() {
    return request({
      method: 'GET',
      url: `/api_v2/medias/adTypes`,
      params: {
        startIndex: 0,
        maxResults: 5000
      }
    });
  }
  export function apiMediaCodeConfigs() {
    return request({
      method: 'GET',
      url: `/api_v2/medias/codeConfigs`,
      params: {
        startIndex: 0,
        maxResults: 5000
      }
    });
  }